import { render, staticRenderFns } from "./CreateUpdateNeighborhood.vue?vue&type=template&id=0c54bd04&scoped=true&"
import script from "./CreateUpdateNeighborhood.vue?vue&type=script&lang=ts&"
export * from "./CreateUpdateNeighborhood.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c54bd04",
  null
  
)

export default component.exports