
    import { Vue } from 'vue-property-decorator';
    import { BFormSelect } from 'bootstrap-vue';
    import * as constants from '@/tsfiles/constants';
    import { logAccessDenied, logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { Neighborhood, AdminService } from '@bostonventurestudio/lola-api';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'CreateUpdateNeighborhoodModal',

        components: {},

        props: {
            isCreateMode: {
                type: Boolean,
                default: true,
            },
            obj: {
                type: Object as PropType<Neighborhood>,
                required: false,
            },
        },
        watch: {
            isCreateMode: {
                immediate: true,
                handler() {
                    if (this.isCreateMode) {
                        this.resetForm();
                    } else {
                        this.initData();
                    }
                },
            },
        },

        data() {
            return {
                neighborhood: {} as Neighborhood,
                markets: [] as string[],
                selectedOption: 'Boston',
                isActive: false,
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.CanSupportUsers()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }
            this.markets = ['Boston'];
        },

        computed: {
            //
            // Should the submit button be disabled?  It should if all required fields are
            // not valid.
            //
            disableSubmit(): boolean {
                return !this.neighborhood.name || !this.selectedOption || !this.neighborhood.location;
            },
            latitudeValid(): boolean {
                if (this.neighborhood.location && this.neighborhood.location.latitude === 0) {
                    return true; // 0 is legal
                } else if (!this.neighborhood.location || !this.neighborhood.location.latitude) {
                    return false;
                }

                return this.neighborhood.location.latitude >= -90.0 && this.neighborhood.location.latitude <= 90.0;
            },

            longitudeValid(): boolean {
                if (this.neighborhood.location && this.neighborhood.location.longitude === 0) {
                    return true; // 0 is legal
                } else if (!this.neighborhood.location || !this.neighborhood.location.longitude) {
                    return false;
                }

                return this.neighborhood.location.longitude >= -180.0 && this.neighborhood.location.longitude <= 180.0;
            },
        },

        methods: {
            async saveNeighborhood() {
                if (this.disableSubmit || !this.neighborhood) {
                    logInvalidParams(this.$options.name, 'submit');
                    return;
                }

                try {
                    this.neighborhood.active = this.isActive;
                    this.neighborhood.marketName = this.selectedOption;
                    const ret = await ApiUtils.apiWrapper(AdminService.saveNeighborhood, this.neighborhood);
                    this.$emit('closed');
                    this.$emit('saved');
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
            resetForm() {
                this.neighborhood = {
                    id: undefined,
                    name: undefined,
                    marketName: undefined,
                    location: {
                        latitude: undefined,
                        longitude: undefined,
                    },
                };
            },
            initData() {
                this.neighborhood = this.obj;
                this.isActive = this.obj.active ?? false;
                this.selectedOption = this.obj.marketName ?? 'Boston';
            },
            async toggle() {
                this.isActive = !this.isActive;
            },
            closeModal() {
                this.$emit('closed');
            },
        },
    });
